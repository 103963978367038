body .admin_intern {
	background-color: #e9e9ea;
}

.admin_intern .box_row {
	border-radius: 10px;
	border: 1px solid #444;
	color: #fff;
	box-shadow: 0 .5rem 1rem rgba(0,0,0,.45);
}

.admin_intern .numbers {
	font-size: 28px;
	font-weight: 600;
}

.admin_intern .text {
	font-size: 19px;
	opacity: 0.75;
}

.admin_intern .icon {
	width: 20px;
}

@media(min-width: 768px) {
	.admin_intern .toggle_neg_margin_row {
		margin-top: -24px;
	}

	.admin_intern .toggle_neg_margin {
		margin-top: -84px;
		margin-right: -27px;
	}
}

@media(max-width: 767px) {
	.admin_intern .toggle_neg_margin_row {
		margin-top: -25px;
	}

	.admin_intern .toggle_neg_margin {
		margin-top: -86px;
		margin-right: -27px;
	}
}
