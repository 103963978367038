:root {
	--navbar_bg_dark: #1d2229;
	--bg_dark_grey: #272c34;
	--bg_very_light_grey: #f2f2f2;
	--zevio_green: #29b869;
	--text_dark_grey: #2a2d32;
	--text_light_grey: #77797e;
	--text_lightlight_grey: #c8ccd1;
	--zevio_font: Poppins;
	--organizer_card_width: 312px;
	--organizer_card_height: 330px;
	--border_grey: #ebebeb;
	--zevio_red: #ee565f;
	--scrollbarBG: #cfd8dc;
	--thumbBG: #90a4ae;
}

a:hover {
	text-decoration: underline;
	color: inherit;
}

a.btn {
	text-decoration: none;
}

.zevio_dark_grey {
	color: var(--text_dark_grey);
}

body {
	color: var(--text_dark_grey);
	background-color: var(--bg_very_light_grey);
	font-family: var(--zevio_font);
}

a {
	text-decoration: none;
}

.nav-item a:hover {
	text-decoration: none;
}

/* no step arrows on number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

@media (min-width: 768px) {
	h1 {
		font-size: 50px;
		font-weight: 700;
		line-height: 75px;
		letter-spacing: 0px;
	}

	h2 {
		font-size: 40px;
		font-weight: 600;
		line-height: 60px;
		letter-spacing: 0px;
	}

	h3 {
		font-size: 35px;
		font-weight: 600;
		line-height: 38px;
		letter-spacing: 0px;
	}

	h4 {
		font-size: 24px;
		font-weight: 600;
		line-height: 25px;
		letter-spacing: 0px;
	}

	h5 {
		font-size: 20px;
		font-weight: 600;
		line-height: 30px;
		letter-spacing: 0px;
	}

	h6 {
		font-size: 16px;
		font-weight: 600;
		line-height: 23px;
		letter-spacing: 0px;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 30px;
		font-style: normal;
		font-weight: 700;
		letter-spacing: 0px;
	}

	h2 {
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		letter-spacing: 0px;
	}

	h3 {
		font-size: 15px;
		font-style: normal;
		font-weight: 600;
		letter-spacing: 0px;
	}

	h4 {
		font-size: 13px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0px;
	}

	h5 {
		font-size: 11px;
		font-weight: 600;
		line-height: 16.5px;
	}
}

@media (max-width: 767px) {
	.w100xs_50 {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.w100xs_50 {
		width: 50%;
	}
}

@media (max-width: 767px) {
	.w45_mobile {
		width: 45%;
	}
}

@media (max-width: 767px) {
	.w100_mobile {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.hero_img_phones {
		width: 75%;
		position: relative;
		bottom: -40px;
		margin-top: -30px;
	}
}

.organized_by {
	font-weight: 600;
	font-size: 15px;
	line-height: 32px;
}

@media (max-width: 767px) {
	.hero_img_phones {
		position: relative;
		bottom: -40px;
		margin-top: -30px;
		width: 100%;
	}
}

@media (max-width: 767px) {
	.logo_mobile {
		width: 60px;
	}
}

.btn.btn_yellow {
	background-color: #fff200;
	border-color: #fff200;
	color: black;
}

.btn.btn_green {
	border: 2px solid var(--zevio_green);
	background-color: var(--zevio_green);
	border-color: var(--zevio_green);
	color: white;
}

.btn.btn_red {
	background-color: var(--zevio_red);
	border: 2px solid var(--zevio_red);
	color: white;
}

.color_green {
	color: var(--zevio_green);
}

.bg_green {
	background-color: var(--zevio_green);
}

.color_green a {
	color: var(--zevio_green);
}

.color_red {
	color: var(--zevio_red);
}

@media (min-width: 768px) {
	.event_profile {
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}
}

@media (min-width: 768px) {
	.countdown_text {
		font-size: 15px;
		font-weight: 400;
		line-height: 28px;
	}

	.countdown_text .timer_digit {
		font-weight: 700;
		font-size: 35px;
		line-height: 26px;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

@media (max-width: 767px) {
	.countdown_text {
		font-size: 11px;
		font-weight: 400;
		line-height: 28px;
	}

	.countdown_text .timer_digit {
		font-weight: 700;
		font-size: 20px;
		line-height: 30px;
		padding-bottom: 0;
		margin-bottom: -10px;
	}
}

@media (max-width: 767px) {
	.event_hero_bottom_info_text {
		font-weight: 400;
		font-size: 13px;
		line-height: 32px;
	}
}
@media (min-width: 768px) {
	.event_hero_bottom_info_text {
		font-weight: 600;
		font-size: 15px;
		line-height: 28px;
	}
}

.btn.btn_green:hover {
	border: 2px solid var(--zevio_green);
	background-color: var(--zevio_green);
	filter: contrast(130%) brightness(110%);
}

.btn.btn_red:hover {
	background-color: var(--zevio_red);
	filter: contrast(130%) brightness(110%);
	border: 2px solid var(--zevio_red);
}

.btn.btn_yellow:hover {
	background-color: #fff200;
	filter: contrast(130%) brightness(110%);
	border: none;
}

.btn.btn_darkgrey {
	color: #f1f3f6;
	border: 2px solid var(--text_dark_grey);
	background-color: var(--text_dark_grey);
}

.btn.btn_grey {
	color: var(--text_dark_grey);
	background-color: #f1f3f6;
	border: 2px solid #f1f3f6;
}

.btn.btn_grey:hover {
	background-color: #e1e3e6;
	color: #000000;
	border: 2px solid #e1e3e6;
}

.btn.btn_editevent {
	width: 100%;
	font-size: 15px;
	line-height: 22px;
	color: var(--text_light_grey);
	background-color: inherit;
}

.btn.btn_white_dark_border {
	border: 2px solid #2a2d32;
	color: var(--text_dark_grey);
	background-color: inherit;
}

.btn.btn_white_dark_border:hover {
	border: 2px solid black;
	color: black;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.17);
}

.red_text {
	color: var(--zevio_red);
}

nav {
	font-size: 16px;
	line-height: 25px;
}

.navbar-collapse {
	z-index: 20;
	background-color: var(--bg_dark_grey);
}

@media (max-width: 767px) {
	.nav-item {
		padding-left: 10px;
	}
}

.navbar_bg_dark {
	background-color: var(--bg_dark_grey);
}

.navbar_becomestreamer_bg_dark {
	background-color: #4A4E55;
}

.navbar_fg_dark {
	color: #3a3d41;
}

@media (min-width: 768px) {
	.navbar_height {
		height: 100px;
	}
	.navbar_user_icon {
		height: 20px;
		margin-bottom: 4px;
	}
}

@media (max-width: 767px) {
	.navbar_height {
		height: 125px;
	}
	.navbar_user_icon {
		height: 14px;
		margin-bottom: 4px;
	}
}

.navbar_toggler:focus,
.navbar_toggler:active,
.navbar_toggler_icon:focus {
	outline: none;
	box-shadow: none;
}

.navbar_organizer_profile_img {
	width: 40px;
	height: 40px;
	border-radius: 20px;
	overflow: hidden;
}

.organizer_hero {
	height: 400px;
	width: 1320px;
}

.get_the_app:hover {
	background-color: var(--bg_dark_grey);
}

.cursor-pointer {
	cursor: pointer;
}

.grey_frontpage {
	color: var(--text_light_grey);
}

.font_weight_500 {
	font-weight: 500;
}

.font_weight_600 {
	font-weight: 600;
}

.font_weight_700 {
	font-weight: 700;
	font-size: inherit;
}

.font_11px {
	font-size: 11px;
	font-style: normal;
	line-height: 17px;
	letter-spacing: 0px;
}

.font_13px {
	font-size: 13px;
	font-style: normal;
	line-height: 24px;
	letter-spacing: 0px;
}

.font_14px {
	font-style: normal;
	font-size: 14px;
	line-height: 32px;
}

.font_15px {
	font-size: 15px;
	font-style: normal;
	line-height: 32px;
	letter-spacing: 0px;
}

.font_15px a {
	text-decoration: none;
	color: var(--text_dark_grey);
}

.font_17px {
	font-size: 17px;
	line-height: 25px;
	letter-spacing: 0px;
}

.font_24px {
	font-size: 24px;
	font-style: normal;
	line-height: 36px;
	letter-spacing: 0px;
}

.font_20px {
	font-size: 20px;
	font-style: normal;
	line-height: 32px;
	letter-spacing: 0px;
}
.font_18px {
	font-size: 18px;
	line-height: 26px;
	letter-spacing: 0px;
}

.font_125pct {
	font-size: 125%;
}

.search_navbar {
	padding: 6px 15px 6px 42px;
	margin-right: 10px;
	height: 50px;
	border-radius: 6px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
}

@media (max-width: 767px) {
	.search_width {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.search_width {
		width: 29%;
	}
}

.search_bg_transparent::placeholder {
	color: #3a3d41;
}

.search_bg_transparent {
	color: var(--text_dark_grey);
	background: url("https://static.zevio.com/imgs/search-icon-dark-2.svg")
		no-repeat 16px 16px;
	background-color: #e7edf2;
	border: 1px solid #e7edf2;
}

.search_bg_dark::placeholder {
	color: white;
}

.search_bg_dark {
	color: white;
	background: url("https://static.zevio.com/imgs/search-icon-1.svg") no-repeat
		16px 16px;
	background-color: #2d3743;
	border: 1px solid #2d3743;
}

.no_outline_focus {
	outline: 0;
}

.input_search::-webkit-input-placeholder {
	font-size: 13px;
	line-height: 19px;
	vertical-align: middle;
}

.background_dark {
	background-color: var(--navbar_bg_dark);
}

.frontpage_hero {
	color: white;
	max-height: 800px;
	background-image: url("https://static.zevio.com/imgs/front-page-hero-bg-1.svg");
	background-repeat: no-repeat;
	background-size: cover;
}

.frontpage_hero .event_card {
	color: var(--text_dark_grey);
}

@media (max-width: 767px) {
	.frontpage_organizer_hero {
		background-color: #f1f6fb;
	}
}
@media (min-width: 768px) {
	.frontpage_organizer_hero {
		background-color: #f1f6fb;
		background-image: url("https://static.zevio.com/imgs/org-landing-tophero-bg-1.svg");
		background-repeat: no-repeat;
		background-position-x: -350px;
		background-position-y: -150px;
	}
}

.frontpage_organizer_badge {
	text-align: center;
	background-color: var(--zevio_green);
	border-radius: 100%;
	width: 40px;
	line-height: 40px;
	color: white;
	font-size: 18px;
	font-weight: 600;
}

.organizer_card {
	width: var(--organizer_card_width);
	height: var(--organizer_card_height);
	border-radius: 10px;
	overflow: hidden;
	scroll-snap-align: start;
}

.organizer_card .img_overlay {
	width: var(--organizer_card_width);
	height: var(--organizer_card_height);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.7) 0%,
		rgba(0, 0, 0, 0) 100%
	);
}

.organizer_icon_some {
	height: 20px;
	width: 20px;
}

@media (max-width: 767px) {
	.organizer_profile_image {
		width: 75px;
		height: 75px;
		margin-top: -30px;
		margin-left: 10px;
	}
}
@media (min-width: 768px) {
	.organizer_profile_image {
		width: 150px;
		height: 150px;
		margin-top: -65px;
		margin-left: 10px;
	}
}

.bg_very_light_grey {
	background: var(--bg_very_light_grey);
}

.bg_white {
	background: white;
}

.successbox {
	background: var(--bg_very_light_grey);
	border-radius: 10px;
}

.promotion_box {
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	overflow: hidden;
}

.bottom_border_promo_listing {
	border-bottom: 1px solid var(--border_grey);
}

.navbar-toggler-icon {
	background-image: url('data:image/svg+xml;utf8,<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H21C21.5523 2 22 1.55228 22 1C22 0.447715 21.5523 0 21 0H1ZM0 9C0 8.44771 0.447715 8 1 8H21C21.5523 8 22 8.44771 22 9C22 9.55229 21.5523 10 21 10H1C0.447715 10 0 9.55229 0 9ZM0 17C0 16.4477 0.447715 16 1 16H21C21.5523 16 22 16.4477 22 17C22 17.5523 21.5523 18 21 18H1C0.447715 18 0 17.5523 0 17Z" fill="white"/></svg>');
}

.zevio_footer {
	background-color: var(--navbar_bg_dark);
	min-height: 100vh;
	min-height: 100%;
}

.zevio_footer a {
	color: var(--text_light_grey);
}

.core_service_entry {
	width: 350px;
}

.faq_entry {
	background-color: white;
	border: 1px solid #e5e8ed;
	border-radius: 6px;
}

.faq_toggle_button {
	border: none;
	background: none;
}

.faq_toggle_button img {
	width: 14px;
}

.login_svg {
	background: rgb(80,74,202);
	background: linear-gradient(360deg, rgba(80,74,202,1) 0%, rgba(41,184,105,1) 100%);
}

.input_login {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid #e5e8ed;
	box-sizing: border-box;
}

.input_login:focus {
	outline: none;
}

.input_login::placeholder {
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 28px;
	color: #2a2d32;
}

.login_or_seperator {
	font-weight: 700;
	font-size: 15px;
	line-height: 28px;
	color: var(--text_lightlight_grey);
}

.login_or_seperator hr {
	color: var(--text_lightlight_grey);
}

.text_no_decoration:hover {
	text-decoration: none;
}

.text_no_decoration {
	text-decoration: none;
}

/* Card hover effect */
@-webkit-keyframes hvr-bob {
	0% {
	  -webkit-transform: translateY(-4px);
	  transform: translateY(-4px);
	}
	50% {
	  -webkit-transform: translateY(-2px);
	  transform: translateY(-2px);
	}
	100% {
	  -webkit-transform: translateY(-4px);
	  transform: translateY(-4px);
	}
  }
  @keyframes hvr-bob {
	0% {
	  -webkit-transform: translateY(-4px);
	  transform: translateY(-4px);
	}
	50% {
	  -webkit-transform: translateY(-2px);
	  transform: translateY(-2px);
	}
	100% {
	  -webkit-transform: translateY(-4px);
	  transform: translateY(-4px);
	}
  }
  @-webkit-keyframes hvr-bob-float {
	100% {
	  -webkit-transform: translateY(-4px);
	  transform: translateY(-4px);
	}
  }
  @keyframes hvr-bob-float {
	100% {
	  -webkit-transform: translateY(-4px);
	  transform: translateY(-4px);
	}
  }
  .hvr-bob {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
  .hvr-bob:hover, .hvr-bob:focus, .hvr-bob:active {
	-webkit-animation-name: hvr-bob-float, hvr-bob;
	animation-name: hvr-bob-float, hvr-bob;
	-webkit-animation-duration: .3s, 1.5s;
	animation-duration: .3s, 1.5s;
	-webkit-animation-delay: 0s, .3s;
	animation-delay: 0s, .3s;
	-webkit-animation-timing-function: ease-out, ease-in-out;
	animation-timing-function: ease-out, ease-in-out;
	-webkit-animation-iteration-count: 1, infinite;
	animation-iteration-count: 1, infinite;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-direction: normal, alternate;
	animation-direction: normal, alternate;
  }

  /* Scroll into view animation effect */
  @keyframes scroll-enter-show {
	0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}
@media (prefers-reduced-motion: no-preference) {
	.scroll-animate {
	  animation: scroll-enter-show 2s;
	  animation-fill-mode: forwards
	}
  }

.target-scroll-animate {
	opacity: 0;
	-webkit-transform: translateY(40px);
}

.card_header {
	font-size: 18px;
	font-weight: 600;
}

.card_product_header {
	font-size: 18px;
	font-weight: 600;
}

.card_product_subtitle {
	font-size: 15px;
	font-weight: 400;
	color: #747474;
}

.card_product_currency {
	font-size: 17px;
	font-weight: 600;
}

.card_product_price {
	font-size: 40px;
	font-weight: 600;
}

.explore_card_scale {
	min-height: 191px;
	height: 191px;
	width: 312px;
	overflow: hidden;
}

.explore_card_scale img {
	width: 100%;
}

.organizer_img_card_scale {
	height: 95px;
	width: 100%;
}

.explore_event_card {
	width: 312px;
	height: 390px;
	border-radius: 10px;
	overflow: hidden;
	scroll-snap-align: start;
	border: none;
}

.product_card {
	width: 312px;
	height: 590px;
	border-radius: 10px;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
	overflow: visible;
	scroll-snap-align: start;
}

.product_modal {
	border-radius: 10px;
	background-color: white;
}

.event_signupmodal_header {
	background-color: #e7edf2;
	border-radius: 10px;
}

.event_signup_modal {
	width: 430px;
	background-color: #fff;
	border-radius: 12px;
}
.event_signup_modal_container .modal-content {
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	border: 0;
}

@media (max-width: 467px) {
	.event_signup_info_container {
		position: absolute;
		overflow: visible;
		bottom: 0;
		background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1));
		height: 175px;
		width: 100%;
	}
	.signup_header {
		font-size: 15px;
		font-weight: 500;
	}
}

@media (min-width: 468px) {
	.event_signup_info_container {
		position: absolute;
		background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1));
		bottom: 0;
		height: 200px;
		width: 100%;
	}
	.signup_header {
		font-size: 21px;
		font-weight: 600;
	}
}

.event_signup_info {
	position: absolute;
	bottom: 4px;
}

.register_custom {
	background-color: #F3F5F9;
}

@media (max-width: 767px) {
	.product_card_modal_img {
		width: 320px;
		height: 320px;
		overflow: hidden;
	}
}
@media (min-width: 768px) {
	.product_card_modal_img {
		width: 350px;
		height: 350px;
		overflow: hidden;
	}
}

.overflow_hidden {
	overflow: hidden;
	padding: 0px;
}

@media (min-width: 768px) {
	.product_nyhed {
		text-align: center;
		background-color: rgba(42, 45, 50, 0.9);
		border-radius: 80px;
		width: 80px;
		height: 80px;
		color: white;
		display: block;
		font-size: 20px;
		line-height: 80px;
		overflow: visible;
		z-index: 1;
	}
	.product_nyhed_margin {
		margin-left: 72%;
		margin-top: -3%;
	}
}
@media (max-width: 767px) {
	.product_nyhed {
		text-align: center;
		background-color: rgba(42, 45, 50, 0.9);
		border-radius: 50px;
		width: 50px;
		height: 50px;
		color: white;
		display: block;
		font-size: 12px;
		line-height: 50px;
		overflow: visible;
		z-index: 1;
	}
	.product_nyhed_margin {
		margin-left: 80%;
		margin-top: -2%;
	}
}

.product_card_img {
	width: 272px;
	height: 272px;
	overflow: visible;
}

.align_right {
	float: right;
}

.pos_right {
	display: flex;
	justify-content: flex-end;
}

.opposites {
	display: flex;
	justify-content: space-between;
}

.vertical_line {
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	height:20px;
	width:1px;
}

.broadcast_event_thumb {
	width: 53px;
	height: 32px;
	border-radius: 5px;
}

.vertical_line_tiny {
	border-right: 1px solid #dee2ea;
}

.vertical_line_dark {
	border-right: 1px solid black;
}

.timer_digit {
	line-height: 0.2;
	font-size: 30px;
	text-align: center;
}

.signup_agree_terms_text {
	color: #909398;
	font-size: 13px;
	line-height: 28px;
	font-weight: 400;
}

.signup_agree_terms_text a {
	color: var(--zevio_green);
	font-weight: 600;
	text-decoration: none;
}

.explore_background_color {
	background-color: #f4f7f9;
	background-size: cover;
}

.collapsible {
	border-radius: 10px;
	border-style: solid;
	border-width: 1px;
	border-color: lightgrey;
}

.banner_getapp {
	height: 70px;
	background-color: #e7edf2;
	border-radius: 12px;
	align-items: center;
}

.badge_grey {
	border-radius: 55px;
	background-color: #eff2f8;
	font-weight: bold;
	font-size: 15px;
	margin-right: 15px;
}

.badge_grey:active {
	background-color: var(--zevio_green);
	filter: contrast(130%) brightness(110%);
}

.badge_white {
	border-radius: 55px;
	border-width: 2px;
	border-color: #d8d8d8;
	background-color: #ffffff;
	font-weight: bold;
	font-size: 15px;
	margin-right: 15px;
}

.badge_white:active {
	background-color: var(--zevio_green);
	filter: contrast(130%) brightness(110%);
}

.discountbadge {
	border-radius: 7px;
	background-color: #f2ebc9;
	width: 98px;
}

.card_badge {
	border-radius: 10px;
	font-size: 14px;
	font-weight: bold;
	padding: 4px 8px 4px 8px;
}

.broadcast_badge {
	display: inline-block;
	text-align: center;
	border-radius: 10px;
	font-size: 14px;
	font-weight: bold;
	padding: 4px 8px 4px 8px;
}

.badgecolor_grey {
	background-color: #f1f3f6;
}

.badgecolor_live {
	background-color: var(--zevio_red);
	color: white;
}

.badgecolor_popular {
	background-color: #2a2d32;
	color: white;
}

.badgecolor_approved {
	background-color: #c7eed9;
	color: #29b869;
}

.badgecolor_pending {
	background-color: #f2ebc9;
	color: #a79334;
}

.badgecolor_hidden {
	background-color: #ffd7d9;
	color: #ee565f;
}

.grey_light {
	color: #909398;
}

.color_white {
	color: white;
}

.btn_no_highlight {
	outline: none !important;
	box-shadow: none !important;
}

.btn_live_become_streamer {
	border-radius: 8px;
	background: linear-gradient(90deg, #297CB8 0%, #2962B8 100%);
	width: 425px;
}
.btn_live_become_streamer .see_more {
	color: #2964B9;
	background-color: white;
	border: none;
	border-radius: 6px;
}

.explanation_text {
	opacity: 0.5;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
}

/* Input custom */

div.input-block {
	position: relative;
}

div.input-block input {
	border: 1px solid #eaeaea;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	color: var(--text_dark_grey);
	font-size: 16px;
	height: 50px;
	margin: 1px 1px 0 1px;
	padding: 0;
	z-index: 1;
}

div.input-block span.placeholder {
	background: #fff;
	border-radius: 0 !important;
	pointer-events: none;
	position: absolute;
	bottom: 17px;
	box-sizing: border-box;
	color: var(--text_dark_grey);
	font-size: 16px;
	font-weight: 400;
	max-width: calc(100% - (2 * 18px));
	overflow: hidden;
	padding: 0 0;
	text-overflow: ellipsis;
	transition: transform 150ms cubic-bezier(0.2, 0, 0.2, 1),
		opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
	white-space: nowrap;
	width:auto;
	z-index: 1;
	opacity: 1;
	left: 0;
}

div.input-block input:valid + span.placeholder,
div.input-block input:focus + span.placeholder,
div.input-block input.has-value + span.placeholder {
	transform: scale(0.7) translate(0, -22px);
	transform-origin: left;
	z-index: 1 important;
	color: var(--text_light_grey);
}

div.input-block input {
	outline: none;
	border-bottom: 1px solid #e5e8ed;
}

.focus-input {
	border-bottom: 1px solid var(--zevio_green) !important;
}
.focus-input:focus {
	outline: black;
}
/* Input custom end */

.zevio_input {
	padding: 6px 15px 6px 12px;
	background-color: rgba(255, 255, 255);
	border: 1px solid #e5e8ed;
	border-radius: 6px;
	color: var(--text_dark_grey);
}

.zevio_input:disabled {
	font-weight: bold;
	padding: 6px 15px 6px 12px;
	background-color: lightgray;
	opacity: 60%;
	border: 1px solid #e5e8ed;
	border-radius: 6px;
}

.zevio_input_dropdown {
	padding: 0px 8px 4px 6px;
	background-color: rgba(255, 255, 255);
	border: none;
	color: var(--text_dark_grey);
}

.zevio_dropdown .dropdown-toggle::after {
	position: absolute;
    right: 8px;
    top: 16px;
}

.zevio_dropdown .btn-secondary {
	background-color: #fff;
	border: 0;
	border-radius: 0;
	border-bottom: 1px solid #e5e8ed;
	color: #2a2d32;
	padding-left: 0;
}

.zevio_dropdown .dropdown-item.active, .zevio_dropdown .dropdown-item:active {
	background-color: var(--zevio_green);
}

.zevio_dropdown .dropdown-menu.show {
	width: 100%;
	max-height: 200px;
	overflow: hidden scroll;
}

.zevio_dropdown .btn-secondary:hover {
	background-color: #fff;
	border: 0;
	border-radius: 0;
	border-bottom: 1px solid var(--zevio_green);
	color: #2a2d32;
}

.btn_radio {
	border: 0;
	background-color: initial;
	font-weight: bold;
	cursor: pointer;
	transition: box-shadow 0.2s;
	padding: 15px;
	padding-right: 15px;
	text-align: start;
}

.btn_radio:disabled {
	color: initial;
	box-shadow: 0 6px 0 #29b869;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
	position: relative;
	margin: 8px;
	width: 54px;
	height: 32px;
	width: unset;
	opacity: 1;
}

.carousel-indicators {
	margin: 0;
}

.carousel-control-prev-icon {
	background-image: url('data:image/svg+xml;utf8,<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Path" d="M0.159138 4.61138L4.55921 0.160979C4.77142 -0.0536594 5.11546 -0.0536594 5.32765 0.160979L5.84085 0.680047C6.0527 0.89432 6.0531 1.2416 5.84175 1.45637L2.35462 5.00001L5.84175 8.54363C6.0531 8.75841 6.0527 9.10568 5.84085 9.31995L5.32765 9.83902C5.11544 10.0537 4.77139 10.0537 4.55921 9.83902L0.159161 5.38862C-0.05305 5.174 -0.0530496 4.82602 0.159138 4.61138Z" fill="%232A2D32"/></svg>');
	background-color: white;
	background-size: 6px 10px;
	border-radius: 50%;
}

.carousel-control-next-icon {
	background-image: url('data:image/svg+xml;utf8,<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Path" d="M5.84086 4.61138L1.44079 0.160979C1.22858 -0.0536594 0.884535 -0.0536594 0.672348 0.160979L0.159153 0.680047C-0.0526957 0.89432 -0.0531032 1.2416 0.158247 1.45637L3.64538 5.00001L0.158247 8.54363C-0.0531032 8.75841 -0.0526957 9.10568 0.159153 9.31995L0.672348 9.83902C0.884558 10.0537 1.22861 10.0537 1.44079 9.83902L5.84084 5.38862C6.05305 5.174 6.05305 4.82602 5.84086 4.61138Z" fill="%232A2D32"/></svg>');
	background-color: white;
	background-size: 6px 10px;
	border-radius: 50%;
}

.carousel-indicators [data-bs-target] {
	border-radius: 50%;
	width: 12px;
	height: 12px;
}

.checkbox_green {
	accent-color: var(--zevio_green);
}

.carousel_dots {
	position: absolute;
	top: 90%;
}

.carousel_text {
	font-size: 45px;
	font-weight: 600;
	line-height: 60px;
	display: block;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.carousel_height {
	height: 550px;
}

.carousel_img_text {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%,rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    position: absolute;
    margin: 0 !important;
    bottom: 0;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    margin-bottom: 40px;
}

.carousel_img_text span {
	margin-bottom: 30px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

@media (min-width: 990px) {
	.pushcontentfix{
		height: 100vh;
	}
}

@media (min-width: 990px) {
	.carousel_explore {
		height: 550px;
		width: 100%;
		background-color: var(--navbar_bg_dark);
	}
	.carousel_img {
		place-items: center;
		display: grid;
		position: absolute;
		top: 15%;
		left: 50%;
	}
	.carousel_img_scale {
		width: 100%;
		height: auto;
		border-radius: 10px;
		overflow: hidden;
	}
	.carousel_gradient {
		position: relative;
	}
}

@media (max-width: 990px) {
	.carousel_img_scale {
		width: 100%;
		height: 100%;
	}
	.carousel_img_text {
		border-radius: 0;
		font-size: 30px;
	}
}

@media (min-width: 768px) {
	.video_player_elem {
		overflow: hidden;
		border-radius: 10px;
		-webkit-transform: translateZ(0);
	}
}

@media (min-width: 768px) {
	.video_symbol_live {
		fill: var(--zevio_red);
		stroke: var(--zevio_red);
		margin-bottom: 3px;
	}

	.video_symbol_ondemand {
		fill: var(--text_lightlight_grey);
		stroke: var(--text_lightlight_grey);
		margin-bottom: 3px;
	}
}
@media (max-width: 767px) {
	.video_symbol_live {
		fill: var(--zevio_red);
		stroke: var(--zevio_red);
		margin-bottom: 3px;
		width: 8px;
	}

	.video_symbol_ondemand {
		fill: var(--text_light_grey);
		stroke: var(--text_light_grey);
		margin-bottom: 3px;
		width: 8px;
	}
}

.link_nostyle {
	color: inherit;
}

.link_nostyle:hover {
	text-decoration: inherit;
}

.transform_scale_1 {
	transform: "scale(1)";
}

.mw_mh_300px {
	max-width: 300px;
	max-height: 300px;
}

.mb_3px {
	margin-bottom: 3px;
}

.mb_2px {
	margin-bottom: 2px;
}

.dropdown_border {
	border: 1px solid #dadada !important;
	border-radius: 8px;
}

.buttonGroup {
	border-bottom: 2px solid #edeff3;
	display: flex;
	flex-grow: 1;
}

.organizer_dropdown_toggle,
.organizer_dropdown_toggle:hover,
.organizer_dropdown_toggle:active {
	font-size: inherit;
	color: inherit;
	background-color: transparent;
	border: none;
}

@media (min-width: 768px) {
	.unrespectful_of_bootstrap_gutter {
		margin-right: -215px;
	}
}

.organizer_dropdown_toggle,
.organizer_dropdown_toggle:hover,
.organizer_dropdown_toggle:active {
	font-size: inherit;
	color: inherit;
	background-color: transparent;
	border: none;
}

.lang_dropdown_flag {
	margin-bottom: 2px;
}

.border_radius_8px {
	border-radius: 8px;
}

.border_radius_10px {
	border-radius: 10px;
}

.icon_modal_delete {
	background-color: #fff3f4;
	border-radius: 50px;
	width: 90px;
	height: 90px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.icon_modal_reset,
.icon_modal_chatname {
	background-color: #e8fff2;
	border-radius: 50px;
	width: 90px;
	height: 90px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.image-container {
	display: flex;
	justify-content: center;
}

.lang_selector {
	border: none;
	background-color: inherit;
	padding-top: 9px;
}

.zevio_chat_username {
	font-size: 85%;
	color: rgba(53, 53, 53, 0.7542);
}
.zevio_chat_username_link img {
	height: 16px;
	margin-bottom: 2px;
	margin-right: 2px;
}

.zevio_chat_message {
	font-size: 90%;
}

.pure_chat_page .zevio_chat_username {
	font-size: 105%;
	color: rgba(53, 53, 53, 0.7542);
}

.pure_chat_page .zevio_chat_message {
	font-size: 110%;
}

.webinar_pure_chat_div .zevio_chat_username_link img {
	margin-bottom: 3px;
}

.zevio_chat_errormsg {
	font-size: 85%;
	color: rgba(53, 53, 53, 0.5032);
}

.zevio_chat_input {
	padding: 6px 15px 6px 30px;
	background: url("data:image/svg+xml;utf8,%3Csvg height='16px' width='16px' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke-linecap='square' stroke-width='2' fill='none' stroke='%23212121' stroke-linejoin='miter' class='nc-icon-wrapper' stroke-miterlimit='10' transform='matrix(0.318182, 0, 0, 0.318182, 0.363633, 0.363633)'%3E%3Cpath d='M6,4H42a4,4,0,0,1,4,4V30a4,4,0,0,1-4,4H26L12,44V34H6a4,4,0,0,1-4-4V8A4,4,0,0,1,6,4Z' style='stroke: rgb(176, 175, 176);'/%3E%3Cline x1='8' y1='27' x2='21' y2='27' style='stroke: rgb(176, 175, 176);'/%3E%3Cline x1='27' y1='27' x2='40' y2='27' style='stroke: rgb(176, 175, 176);'/%3E%3Cline x1='8' y1='21' x2='31' y2='21' style='stroke: rgb(176, 175, 176);'/%3E%3Cline x1='37' y1='21' x2='40' y2='21' style='stroke: rgb(176, 175, 176);'/%3E%3C/g%3E%3C/svg%3E")
		no-repeat 8px 7px;
	border: 1px solid #dddddd;
	height: 30px;
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
}
.chat_tooltip {
	font-size: 9px;
	color: rgba(53, 53, 53, 0.6542);
}

.chat_tooltip a {
	color: black;
	text-decoration: underline;
}

@media (min-width: 768px) {
	.golive_page_chat {
		width: 408px;
	}
}

@media (max-width: 767px) {
	.golive_page_chat {
		width: 100%;
	}

	.golive_haircheck {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.live_page_chat {
		width: 433px;
	}
}

@media (max-width: 767px) {
	.live_page_chat {
		width: 100%;
	}
}

@media (min-width: 1400px) {
	.inner_chat_component {
		height: 500px;
	}
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.inner_chat_component {
		height: 398px;
	}
	/* Hide timestamp on mobile */
	.explore_event_card {
		width: 275px;
	}
	.badge_time {
		display: none;
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.inner_chat_component {
		height: 297px;
	}
}

.border_1px_solid {
	border: 1px solid #e5e8ed;
}

.dropdown_scroll_menu {
	height: 400px;
	overflow-y: scroll;
}

.Select-option.is-selected {
	color: red;
}

.logo_navbar {
	width: 48px;
	height: 48px;
}

.custom_scrollbar_section {
	scrollbar-width: thin;
	scrollbar-color: var(--zevio_green) var(--scrollbarBG);
	overflow-y: auto;
}

@media (min-width: 768px) {
	.watch_video_info {
		font-weight: 600;
		font-size: 15px;
		line-height: 28px;
		height: 30px;
	}
}

@media (max-width: 767px) {
	.watch_video_info {
		font-weight: 500;
		font-size: 13px;
		line-height: 32px;
	}
}

.scrolling-wrapper-flexbox::-webkit-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
	display: none;
}

.scrolling-wrapper-flexbox {
	-ms-overflow-style: none;
	scrollbar-width: none;
	display: none;
	scroll-snap-type: x mandatory;
}

@media only screen and (max-width: 767px) {
	.scrolling-wrapper-flexbox {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		-ms-overflow-style: scrollbar;
		scrollbar-width: thin;
	}

	.scrolling-wrapper,
	.scrolling-wrapper-flexbox {
		height: auto;
		-webkit-overflow-scrolling: touch;
	}

	.scrolling-wrapper::-webkit-scrollbar,
	.scrolling-wrapper-flexbox::-webkit-scrollbar {
		display: none;
	}
}

@media (min-width: 768px) {
	.scrolling-wrapper-flexbox {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
	}

	.scrolling-wrapper,
	.scrolling-wrapper-flexbox {
		height: auto;
		-webkit-overflow-scrolling: scroll;
	}
}

.blur_end_scrollables_white {
	mask-image: linear-gradient(
		to right,
		transparent,
		black 0%,
		black 98%,
		transparent 100%
	);
	webkit-mask-image: linear-gradient(
		to right,
		transparent,
		black 0%,
		black 98%,
		transparent 100%
	);
}

.scroll-smooth {
	scroll-behavior: smooth;
}

@media (max-width: 767px) {
	.organizer_landing_headline {
		font-size: 24px;
		font-weight: 600;
	}

	.organizer_landing_top_promo_text {
		font-size: 16px;
		line-height: 32px;
		font-weight: 400px;
	}

	.organizer_landing_promo_text {
		font-size: 13px;
		line-height: 22px;
		font-weight: 400px;
	}

	.organizer_promo_numbers {
		font-size: 24px;
		line-height: 36px;
		font-weight: 600px;
	}

	.organizer_promo_numbers_text {
		font-size: 15px;
		line-height: 22.5px;
		font-weight: 700px;
	}
	.explore_subtitle {
		line-height: 32px;
		font-size: 15px;
		font-weight: 600;
	}
}

@media (min-width: 768px) {
	.organizer_landing_headline {
		font-size: 40px;
		font-weight: 600;
		line-height: 60px;
	}

	.organizer_landing_promo_text {
		font-size: 15px;
		line-height: 28px;
		font-weight: 400px;
	}

	.organizer_landing_top_promo_text {
		font-size: 20px;
		line-height: 35px;
		font-weight: 400px;
	}

	.organizer_promo_numbers {
		font-size: 50px;
		line-height: 75px;
		font-weight: 600px;
	}

	.organizer_promo_numbers_text {
		font-size: 30px;
		line-height: 20px;
		font-weight: 400px;
	}

	.explore_subtitle {
		line-height: 32px;
		font-size: 20px;
		font-weight: 600;
	}
}

.register_consent {
	font-size: 12px;
}

.green_link a {
	color: var(--zevio_green);
}

@media (max-width: 767px) {
	.signup_organizers_modal {
		font-size: 14px;
	}
}
@media (min-width: 768px) {
	.signup_organizers_modal {
		font-size: 16px;
	}
}

@media (max-width: 767px) {
	.signup_nyhed_margin {
		margin-left: 80%;
		margin-top: -6%;
	}
}

@media (min-width: 768px) {
	.signup_nyhed_margin {
		margin-left: 70%;
		margin-top: -7%;
	}
}

.privacy_page {
	font-family: monospace;
	font-size: 13px;
}

.privacy_page h1 {
	font-family: monospace;
	font-size: 190%;
	font-weight: bold;
}

.privacy_page h2 {
	font-family: monospace;
	font-size: 150%;
	font-weight: bold;
	line-height: 120%;
}

.privacy_page h3 {
	font-family: monospace;
	font-size: 135%;
	font-weight: bold;
	line-height: 120%;
}

.privacy_page h4 {
	font-family: monospace;
	font-size: 120%;
	font-weight: bold;
	line-height: 110%;
}

.search_icon {
	width: 53px;
	height: 46px;
	border-radius: 6px;
	object-fit: cover;
}

@media (min-width: 768px) {
	.search_dropdown {
		width: 738px;
	}
}

.lineclamp {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.mywebinars_smallimg img {
	width: 48px;
	height: 40px;
	border-radius: 6px;
	overflow: hidden;
}

@media (max-width: 767px) {
	.mywebinars_date {
		font-size: 10px;
		line-height: 18px;
		color: rgba(42, 45, 50, 0.4);
	}

	.mywebinars_speakers {
		font-size: 10px;
		line-height: 18px;
	}
}

.mywebinars_toggledropdown {
	border: none;
	background-color: transparent;
}

.mw_944px {
	max-width: 944px;
}

.break-word {
	word-break: break-word;
}

@media (min-width: 1400px) {
	.overflow_visible {
		overflow: visible;
	}
}

.px_100px {
	padding-left: 100px;
	padding-right: 100px;
}

.bg_lightlight_grey {
	background: #e5e5e5;
}

.font_monospace {
	font-family: monospace;
}

.text_underline {
	text-decoration: underline;
}

.eventurl_box {
	background-color: white;
	outline: solid 1px;
	outline-color: lightgrey;
}

.text_oneline {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: clip;
}

.productevent_thumb {
	height: 40px;
	width: 40px;
	border-radius: 6px;
}

.min_vw100 {
	min-width: 100vw;
}

.min_vh100 {
	min-height: 100vh;
}

.min_w100 {
	min-width: 100%;
}

.min_h100 {
	min-height: 100%;
}

@media (max-width: 767px) {
	.event_cls {
		min-height: 150px;
	}
}

@media (min-width: 768px) {
	.event_cls {
		min-height: 20vh;
	}
}

.ql-editor {
	min-height: 7rem;
}

.h_56pct {
	height: 56.25%;
	min-height: 56.25%;
}

.orderstatustabselected {
	color: inherit;
	background-color: #ffffff;
	border-radius: 8px 8px 0 0;
	border-top: 1px solid rgba(0, 0, 0, 0.3);
	border-left: 1px solid rgba(0, 0, 0, 0.3);
	border-right: 1px solid rgba(0, 0, 0, 0.3);
	border-bottom: 0;
	box-shadow: 5px -5px 7px -4px #ccc ;
}

.orderstatustabspacing {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.orderstatustabdeselected {
	opacity: 0.5;
	border-radius: 8px 8px 0 0;
	background-color: rgba(0, 0, 0, 0.05);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	border-left: 1px solid rgba(0, 0, 0, 0.1);
	border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.event_profile_descriptions p {
	margin-bottom: 0;
}

.overflow_wrap {
	overflow-wrap: break-word;
}
